import React from 'react'
import Tour from 'reactour'
import { navbar_steps } from './TourSteps';

function NewDashboardTour({ open, close }) {
    const accentColor = "#4285f4";

    const steps = [
        ...navbar_steps
    ]

    const onClose = () => {
        localStorage.setItem('saleassist_tour_seen', 'true');
        close();
    }

    return (
        <Tour
            steps={steps}
            isOpen={open}
            onRequestClose={onClose}
            accentColor={accentColor}
            rounded={5}
            startAt={0}
        />
    )
}

export default NewDashboardTour
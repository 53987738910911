import React, { useEffect, useState } from 'react'
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import { getCall } from '../../../Helpers/Api';
import { config } from '../../../config';
import { switchOrganization } from '../../../Helpers/Client';
import { bottomAlert } from '../../../Components/Notifications/ToastAlerts';

function SwitchOrganizationModal({activeClientID, open, handleClose}) {
    const [allowedClients, setAllowedClients] = useState();
    const [selectedOrg, setSelectedOrg] = useState(null);

    const fetchAllOrg = () => {
        getCall({
            url: config.url.CLIENTS,
            path: `/allowed_clients`,
            onSuccess: (response) => {
                const filteredData  = response.data.filter(client => client.is_enabled === "True").sort((a, b) => a.name.localeCompare(b.name));
                setAllowedClients(filteredData);
            },
            onFailure: (error) => {
                bottomAlert("We are unable to fetch your organizations. " + ((error.response && error.response.data.detail) || error.toString()), "warning");
            },
        });
    };

    const handleSubmit = () => {
        if(activeClientID === selectedOrg.id) {
            bottomAlert("You are already in the selected Organization!", "warning");
        } else {
            switchOrganization(selectedOrg.id);
        }
    }

    useEffect(() => {
        if (open) {
            fetchAllOrg();
            setSelectedOrg(null);
        }

        return () => {}
    }, [open])

    useEffect(() => {
        if (allowedClients && activeClientID) {
            const activeOrg = allowedClients.find(client => client.id === activeClientID);
            setSelectedOrg(activeOrg || null);
        }

        return () => {}
    }, [allowedClients, activeClientID]);
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Select an Organization</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please select an organization from the list below to switch your active workspace.
                </DialogContentText>
                <Autocomplete
                    options={allowedClients}
                    getOptionLabel={(option) => option.name}
                    value={selectedOrg}
                    onChange={(event, newValue) => {
                        setSelectedOrg(newValue)}
                    }
                    renderInput={(params) => <TextField {...params} label="Organization" />}
                    sx={{ width: 300, mt:3 }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit}>Switch</Button>
                <Button color='error' onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SwitchOrganizationModal
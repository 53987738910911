import React, { Component } from "react";
import { postCall, putCall} from "../../../../Helpers/Api"
import {currentTimeInMilliSecs, msToShortDateString} from "../../../../Helpers/Time"
import { config } from "../../../../config";
import {
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import {Button} from "reactstrap";
import { bottomAlert } from "../../../../Components/Notifications/ToastAlerts";


class NotificationElement extends Component {
  constructor(props) {
    super(props);
    this.changeStatus=this.changeStatus.bind(this);
    this.state=this.props.notification;
  }

  changeStatus(notification_id) {
    postCall({
        path: "/toggle_read/" + notification_id,
        url: config.url.NOTIFICATIONS,
        onSuccess: (response) => {
          this.setState({read_on: response.data});
        },
        onFailure: (error) => {
          bottomAlert("Unable to update read status. " + ((error.response && error.response.data.detail) || error.toString()), "warning");
        }
    });
  }

  take_action(notification_id, action_link) {
    postCall({
      url:  config.url.NOTIFICATIONS,
      path: `/take_action/${notification_id}/${action_link.name}`,
      onSuccess: (res) => {
        this.setState({action_taken: true, read_on: currentTimeInMilliSecs()});
        bottomAlert(action_link.on_success, "success");
      },
      onFailure:(error) => {
        this.setState({action_taken: false});
        bottomAlert(action_link.on_failure + " " + ((error.response && error.response.data.detail) || error.toString()), "warning");
      }
    });
  }

  render() {
    var action_links = [];
    this.state.action_links.forEach((action_link,index) => {
      action_links.push(
        <Button key={`notification-${index}`} style={this.props.noTimeLine ? button : {}} className={`${this.props.noTimeLine ? "" : "ml-2"} bg-` + action_link.color} onClick = {()=>{this.take_action(this.state.id, action_link); }}>
          {action_link.name}
        </Button>
      );
    });

    return this.props.noTimeLine ? (
      <>
        <div style={notificationContent}>
          <h4 style={title} className="timeline-title">{this.state.content}</h4>
          <p style={timestamp}>{msToShortDateString(this.state.created_on)} <span className={this.state.read_on > 0 ? "text-success" : "text-warning"}>{this.state.read_on > 0 }</span></p>
        </div>
        {!this.state.action_taken &&
          <div style={actionLinks}>
            {action_links}
          </div>
        }
      </>
    ) : (
      <VerticalTimelineElement className="vertical-timeline-item" icon={<i className={"badge badge-dot badge-dot-xl badge-" + (this.state.read_on > 0 ? "success" : "warning")}>{"."}</i>}>
        <div>
          <h4 className="timeline-title">{this.state.content}</h4>
          <p>{msToShortDateString(this.state.created_on)} <span className={this.state.read_on > 0 ? "text-success" : "text-warning"}>{this.state.read_on > 0 }</span></p>
        </div>
        {!this.state.action_taken &&
          <div style={{float:"right"}}>
            {action_links}
          </div>
        }
      </VerticalTimelineElement>
    );
  }
}

export default NotificationElement;

const notificationContent = {
  display: "flex",
  flexDirection: "column",
  gap: "2px",
}

const title = {
  margin: 0,
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "normal",
  color: "#333",
}

const timestamp = {
  margin: 0,
  fontSize: "10px",
  fontWeight: 400,
} 

const actionLinks = {
  display: "flex",
  gap: "6px",
}

const button = {
  fontSize: "10px",
  fontWeight: 400,
  padding: "2px 8px",
  border: "none",
  borderRadius: "6px",
}
import { bottomAlert } from "../Components/Notifications/ToastAlerts";
import { config } from "../config";
import { clientStore } from "../States/Client";
import { notificationsHandle } from "../States/Connections";
import { getCall, postCall } from "./Api";
import { app_cleanup } from "./User";

export function getClient(){
  return clientStore.getState();
}

export function fetchClient(onSuccess, onFailure) {
  getCall({
    url: config.url.CLIENTS,
    path: getClient().id,
    onSuccess: (response) => {
      onSuccess(response);
    },
    onFailure: (error) => {
      onFailure(error);
    }
  });
}

export function switchOrganization(identifier) {
  if(identifier) {
    notificationsHandle.close();
    postCall({
      url: config.url.USERS,
      path: `/switch_client/${identifier}`,
      onSuccess: (response) => {
        app_cleanup();
        bottomAlert("Let's get you to the selected client.", "success");
        window.location.reload();
      },
      onFailure: (error) => {
        bottomAlert(
          "We are Unable to switch the client. " +
          ((error.response && error.response.data.detail) ||
            error.toString()),
          "warning"
        );
      },
    });
  }
};
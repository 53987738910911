import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { config } from '../../config';
import { MdGroup, MdOutlineAdminPanelSettings, MdOutlineDashboard, MdOutlineHelpOutline, MdOutlineLogout, MdOutlineSettings, MdOutlineStore } from "react-icons/md";
import { AiOutlineThunderbolt } from 'react-icons/ai';
import { TbReport } from 'react-icons/tb';
import { LuBuilding, LuChevronFirst, LuChevronLast } from 'react-icons/lu';
import { getPermissions } from '../../States/Permissions';
import { getUser, logout } from '../../Helpers/User';
import { getClient } from '../../Helpers/Client';
import { agentStatus } from '../../States/Meetings';
import { getCall, postCall } from '../../Helpers/Api';
import { Tooltip } from '@mui/material';
import SwitchOrganizationModal from './SwitchOrganizationModal/SwitchOrganizationModal';
import Notifications from './Notifications/Notifications';
import NewDashboardTour from '../../Components/DashboardTour/NewDashboardTour';
import "./vertical-navigation-bar.scss";

const navItems = [
  {label: "Home", href: config.landing_page, icon: <MdOutlineDashboard />},
  {label: "Leads", href: "/workspace/conversations", icon: <MdGroup />},
  {label: "Assets", href: "/assets", icon: <MdOutlineStore />},
  {label: "Reports", href: "/workspace/reports", icon: <TbReport />},
  {label: "Strike", href: "/workspace/strike-analytics", icon: <AiOutlineThunderbolt />},
  {label: "Settings", href: "/settings", icon: <MdOutlineSettings />},
]

function VerticalNavigationBar() {
  const user = getUser();
  const client = getClient();
  const permissions = getPermissions();
  const history = useHistory();
  
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getAgentStatus, setAgentStatus] = useState(agentStatus.get());
  const [isTourOpen, setIsTourOpen] = useState(localStorage.getItem("saleassist_tour_seen") !== "true");

  const isSuperAdmin = ["chetan@saleassist.ai", "deep@saleassist.ai", "satyam@saleassist.ai"].includes(user.email);
  const isDifferentSignSource = client.sign_source !== "saleassist";

  const filteredNavItems = navItems.filter((item) => {
    if (item.label === "Assets" && permissions?.includes("outbound_agent")) return false;
    if (item.label === "Reports" && !permissions?.includes("admin")) return false;
    if (item.label === "Strike" && permissions?.includes("outbound_agent")) return false;
    return true;
  });

  const fetchAgentStatus = () => {
    getCall({
      url: config.url.SOURCES,
      path: "/availability/status",
      onSuccess: (response) => {
        agentStatus.set(response.data);
        setAgentStatus(response.data);
      },
      onFailure: (error) => {
        bottomAlert(error.response ? error.response.data.detail : error.toString(), "warning");
      },
    });
  }

  const handleToggle = () => {
    if(getAgentStatus === "busy") return;

    postCall({
      url: config.url.SOURCES,
      path: "/availability/toggle_live",
      onSuccess: (response) => {
        setAgentStatus(response.data);
        agentStatus.set(response.data);
      },
      onFailure: (error) => {
        bottomAlert(error.response ? error.response.data.detail : error.toString(), "warning");
      },
    });
  }

  const handleAgentStatus = (status) => setAgentStatus(status);

  useEffect(() => {
    agentStatus.subscribe(handleAgentStatus);
    fetchAgentStatus();
  
    return () => {
      agentStatus.unsubscribe(handleAgentStatus);
    }
  }, [])
  
  return (
    <nav className={`vertical-nav-bar ${isExpanded ? "expanded" : ""}`}>
      <div className='nav-header'>
        {!isDifferentSignSource && <img src="https://client-static.saleassist.ai/10b07b83-c8e3-4b74-8c06-3eb5923794af/SaleAssistLogo" alt=""/>}
        <button nav-tour="nav_Collapse" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <LuChevronFirst /> : <LuChevronLast />}
        </button>
      </div>

      <ul className='nav-items'>
        {filteredNavItems.map((item) => (
          <Tooltip title={item.label} placement="right" disableHoverListener={isExpanded} disableTouchListener={isExpanded}>
            <li key={item.label} nav-tour={`nav_${item.label}`}>
              <Link className="item" to={item.href} target={getAgentStatus === "busy" ? "_blank" : "_self"}>
                <span>{item.icon}</span>
                <span>{item.label}</span>
              </Link>
            </li>
          </Tooltip>
        ))}
        <Tooltip title="Help" placement="right" disableHoverListener={isExpanded} disableTouchListener={isExpanded}>
          <li key="Help" nav-tour="nav_Help" className='item' onClick={() => setIsTourOpen(true)}>
              <span><MdOutlineHelpOutline /></span>
              <span>Help</span>
          </li>
        </Tooltip>

      </ul>

      <Notifications isNavExpanded={isExpanded} />

      <div className='nav-footer'>
        <div className='user-info'>
          <Tooltip title="Set Availability" placement="right" disableHoverListener={isExpanded} disableTouchListener={isExpanded}>
            <label className='user-status-toggle' nav-tour="nav_Status">
              <input type="checkbox" checked={getAgentStatus !== "offline"} onChange={handleToggle} />
              <div className={getAgentStatus} data-text={user.display_name[0].toUpperCase() || ""}></div>
            </label>
          </Tooltip>
          
          <div className='user-details'>
            <p>{user.display_name}</p>
            <p>{user.user_name}</p>
          </div>
        </div>

        {isExpanded && (
          <div className='user-status'>
            <span className={getAgentStatus} ></span>
            <span>{getAgentStatus === "busy" ? "in-call" : getAgentStatus}</span>
          </div>
        )}

        <div className='user-actions'>
          {!isDifferentSignSource && (
            <Tooltip title="Switch Organization" placement="right" disableHoverListener={isExpanded} disableTouchListener={isExpanded}>
              <button nav-tour="nav_Org" onClick={() => setIsModalOpen(true)}>
                <span><LuBuilding /></span>
                <span>{client.name}</span>
              </button>
            </Tooltip>
          )}
          {isSuperAdmin && (
            <Tooltip title="Super Admin" placement="right" disableHoverListener={isExpanded} disableTouchListener={isExpanded}>
              <button onClick={() => history.push("/admin")}>
                <span><MdOutlineAdminPanelSettings /></span>
                <span>Super Admin</span>
              </button>
            </Tooltip>
          )}
          {!isDifferentSignSource && (
            <Tooltip title="Log Out" placement="right" disableHoverListener={isExpanded} disableTouchListener={isExpanded}>
              <button nav-tour="nav_Logout" onClick={logout}>
                <span><MdOutlineLogout /></span>
                <span>Log Out</span>
              </button>
            </Tooltip>
          )}
        </div>
      </div>

      <SwitchOrganizationModal activeClientID={user.active_client_id} open={isModalOpen} handleClose={() => setIsModalOpen(false)} />
      <NewDashboardTour open={isTourOpen} close={() => setIsTourOpen(false)} />
    </nav>
  )
}

export default VerticalNavigationBar
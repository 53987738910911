import React, { useEffect, useRef, useState } from 'react';
import { getCall } from '../../../Helpers/Api';
import { config } from '../../../config';
import { Skeleton, Tooltip } from '@mui/material';
import { IoIosNotifications } from 'react-icons/io';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { noDelaySetInterval } from '../../../Helpers/Time';
import NotificationElement from '../../AppHeader/Components/TabsContent/NotificationElement';
import "./notifications.scss";

function Notifications({ isNavExpanded }) {
    const notificationRef = useRef(null);
    const history = useHistory();
    const [count, setCount] = useState();
    const [notifications, setNotifications] = useState([]);
    const [active, setActive] = useState(1);
    const [isNext, setIsNext] = useState(true);

    const fetchUnreadNotifications = () => {
        getCall({
            url: config.url.NOTIFICATIONS,
            path: "/unread_notification",
            onSuccess: (response) => {
                setNotifications(response.data.records);
                setCount(response.data.count);
            },
            onFailure: (error) => {
                console.log(error);
            },
        });
    }

    const handleViewAllNotifications = () => {
        history.push("/history/notifications");
    }

    const onNextClick = () => {
        if (isNext) {
            if (active < count) {
                setActive(prev => prev + 1);
            } 
            if (active + 1 === count) {
                setIsNext(false);
            }
        } else {
            if (active > 1) {
                setActive(prev => prev - 1);
            } 
            if (active - 1 === 1) {
                setIsNext(true);
            }
        }
    }

    const triggerAnimation = () => {
        const div = notificationRef.current;
        if (div) {
          div.style.opacity = 0;
          div.classList.remove('animate');
          void div.offsetWidth;
          div.classList.add('animate');
        }
    };

    useEffect(() => {
        const intervalID = noDelaySetInterval(fetchUnreadNotifications, 4000);
    
        return () => clearInterval(intervalID);
    }, [])

    useEffect(() => {
        triggerAnimation();

        return () => {}
    }, [isNavExpanded])
    

    if (!isNavExpanded) {
        return (
            <Tooltip title="View All Notifications" placement='right'>
                <div ref={notificationRef} className='notifications-section icon' onClick={handleViewAllNotifications}>
                    <div className='notification-header center'>
                        <span><IoIosNotifications /></span>
                        {count > 0 && <span className='count'>{count > 9 ? "9+" : count}</span>}
                    </div>
                </div>
            </Tooltip>
        )
    }
      
    if (isNavExpanded) {
        if(count === 0) {
            return (
                <div ref={notificationRef} className='notifications-section' onClick={handleViewAllNotifications}>
                    <div className='notification-header center'>
                        <span>View All Notifications</span>
                    </div>
                </div>
            )
        }

        if (count > 0) {
            return (
                <div ref={notificationRef} className='notifications-section'>
                    <div className='notification-header'>
                        <span>Unread Notifications</span>
                        <span>{active}/{count}</span>
                    </div>
                    <div className='notification-content'>
                        {notifications.length > 0 && <NotificationElement noTimeLine key={notifications[active - 1]?.id} notification={notifications[active - 1]}/>}
                    </div>
                    <div className='notification-footer'>
                        <span onClick={onNextClick}>{count > 1 && (isNext ? "Next" : "Prev")}</span>
                        <span onClick={handleViewAllNotifications}>View All ({count})</span>
                    </div>
                </div>
            )
        }
    }

    return <Skeleton variant="rounded" sx={{m: "0.75rem"}} height={45} />;
}

export default Notifications
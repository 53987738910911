import React from 'react'

export const navbar_steps = [
    {
        selector: '[nav-tour="nav_Collapse"]',
        content: () => (
            <>
                <h5>Collapse Menu</h5>
                <p>Click here to collapse or expand the navigation menu for a cleaner workspace.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Home"]',
        content: () => (
            <>
                <h5>Home</h5>
                <p>Navigate to the homepage where you can find an overview of key activities and insights.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Leads"]',
        content: () => (
            <>
                <h5>Leads</h5>
                <p>Manage your leads efficiently. Track potential customers, follow up, and convert them into sales.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Assets"]',
        content: () => (
            <>
                <h5>Assets</h5>
                <p>Access and manage all your widgets and video tiles in one place.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Reports"]',
        content: () => (
            <>
                <h5>Reports</h5>
                <p>View and analyze reports to gain insights into your performance and business growth.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Strike"]',
        content: () => (
            <>
                <h5>Strike</h5>
                <p>Monitor and manage Strike related Analytics.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Settings"]',
        content: () => (
            <>
                <h5>Settings</h5>
                <p>Customize your account and organisation settings, preferences, and notifications according to your needs.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Status"]',
        content: () => (
            <>
                <h5>Set Availability</h5>
                <p>Click here to update your availability status.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Org"]',
        content: () => (
            <>
                <h5>Switch Organization</h5>
                <p>Manage multiple organizations by switching between them effortlessly.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Logout"]',
        content: () => (
            <>
                <h5>Log Out</h5>
                <p>Sign out of your account securely. Make sure to save your work before logging out.</p>
            </>
        )
    },
    {
        selector: '[nav-tour="nav_Help"]',
        content: () => (
            <>
                <h5>Help & Support</h5>
                <p>Click here to restart the tour of our platform anytime you need help..</p>
            </>
        )
    },
]